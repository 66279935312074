<template>
  <el-card shadow="hover" header="" class="x-card-title-slot">
    <div class="row no-gutters align-items-center" slot="header">
      <div class="col header-title">
        运费设置
      </div>

      <el-button type="primary" @click="handleSaveShopData('shopDataForm')" size="small">
        保存
      </el-button>
    </div>

    <el-form
      :model="shopDataForm"
      ref="shopDataForm"
      class="demo-ruleForm"
    >
      <el-form-item label="运费模式选择" label-width="100px">
        <div style="margin-bottom:10px">
          <el-radio v-model="source_type" :label="1">
            <span>所有商品统一设置商品模式</span>
          </el-radio>
          <span class="ss">任何渠道来源的商品,对客户和用户运费统一设定</span>
        </div>
      </el-form-item>

      <el-form-item label="企业运费设置" v-show="source_type === 1 && parentLogin === 1" error="true">
        <el-alert slot="error" type="error" title="说明" :closable="false" style="line-height: 1.7; margin-top: 12px">
          1. 企业运费用于企业向站点商城收取运费，运费的满足金额以订单中，商品的分销价为标准。<br>
          2. 企业运费会按照供应商来源不同分开收运费，运费金额随订单扣减 站点账户余额一并扣除。
        </el-alert>

        <span>企业运费按照满</span>
        <el-input v-model="enterpriseParams.full_money" placeholder="0.0" size="small"></el-input>
        <span>元包邮,不满则收取</span>
        <el-input v-model="enterpriseParams.money" placeholder="0.0" size="small"></el-input>
        <span>元运费。</span>
      </el-form-item>

<!--      <el-form-item label="用户运费设置" v-show="source_type === 1" error="true">-->
<!--        <el-alert slot="error" type="error" title="说明" :closable="false" style="line-height: 1.7; margin-top: 12px">-->
<!--          1. 用户运费用于收取用户在商城消费时产生的运费，运费的满足金额以订单中，商品的销售价为标准。<br>-->
<!--          2. 用户运费不会按照供应商分开收运费，每个订单，只会收取用户一次运费，运费金额随用户支付订单一起支付。-->
<!--        </el-alert>-->

<!--        <span>用户运费按照满</span>-->
<!--        <el-input v-model="userParams.full_money" placeholder="0.0" size="small"></el-input>-->
<!--        <span>元包邮,不满则收取</span>-->
<!--        <el-input v-model="userParams.money" placeholder="0.0" size="small"></el-input>-->
<!--        <span>元运费。</span>-->
<!--      </el-form-item>-->
    </el-form>
  </el-card>
</template>

<script>
import Storage from '@/utils/storage';
import * as API_shop from '@/api/shop';
import * as API_goods from '@/api/goods';
import {mapGetters} from 'vuex';

export default {
  computed: {
    ...mapGetters(['shopInfo'])
  },
  name: 'transportaionExp',
  data() {
    return {
      shopDataForm: {
        maxMoney: '',
        minMoney: ''
      },
      params: {
        jd_source_postage: [
          {
            hign_money: 0,
            low_money: 0,
            money: 0
          }
        ],
        jy_source_postage: [
          {
            hign_money: 0,
            low_money: 0,
            money: 0
          }
        ],
        gys_source_postage: [
          {
            hign_money: 0,
            low_money: 0,
            money: 0
          }
        ],
        gys_enterprise_postage: [
          {
            hign_money: 0,
            low_money: 0,
            money: 0
          }
        ],
        jd_enterprise_postage: [
          {
            hign_money: 0,
            low_money: 0,
            money: 0
          }
        ],
        jy_enterprise_postage: [
          {
            hign_money: 0,
            low_money: 0,
            money: 0
          }
        ]
      },
      source_type: 1,
      userParams: {
        full_money: '',
        money: '',
        id: '',
        is_use: '1',
        postage_type: 1
      },
      enterpriseParams: {
        full_money: '',
        money: '',
        id: '',
        is_use: '1',
        postage_type: 3
      },
      sList1: [],
      sList: [],
      parentLogin: 1
    };
  },
  mounted() {
    API_goods.getLoginType().then(res => {
      this.parentLogin = res.parentLogin
    })
    this.source_type = this.shopInfo.postage_type && this.shopInfo.postage_type == 2 ? 2 : 1;
    this.getDetail();
    this.getTongYi();
    const user = Storage.getItem('admin_user');
  },
  methods: {
    del(ty, ind) {
      if (ty == 1) {
        this.params.jd_source_postage.splice(ind, 1);
      } else if (ty == 2) {
        this.params.jy_source_postage.splice(ind, 1);
      } else if (ty == 3) {
        this.params.gys_source_postage.splice(ind, 1);
      } else if (ty === 4) {
        this.params.jd_enterprise_postage.splice(ind, 1)
      } else if (ty === 5) {
        this.params.jy_enterprise_postage.splice(ind, 1)
      } else {
        this.params.gys_enterprise_postage.splice(ind, 1)
      }
    },
    pub(ty) {
      let temp = {
        hign_money: 0,
        low_money: 0,
        money: 0
      };
      if (ty == 1) {
        this.params.jd_source_postage.push(temp);
      } else if (ty == 2) {
        this.params.jy_source_postage.push(temp);
      } else if (ty === 3) {
        this.params.gys_source_postage.push(temp);
      } else if (ty === 4) {
        this.params.jd_enterprise_postage.push(temp);
      } else if (ty === 5) {
        this.params.jy_enterprise_postage.push(temp);
      } else {
        this.params.gys_enterprise_postage.push(temp);
      }
    },
    handleSaveShopData(formName) {
      if (this.source_type == 1) {
        let list = []
        if (this.parentLogin === 1) {
          list.push(this.userParams)
          list.push(this.enterpriseParams)
        } else {
          list.push(this.userParams)
        }
        API_shop.saveAllSendMoney(list).then(res => {
          this.$message.success('保存成功！');
          this.getTongYi();
        });
      } else {
        let flag = true;
        let arr = [
          'gys_source_postage',
          'jd_source_postage',
          'jy_source_postage',
          'gys_enterprise_postage',
          'jd_enterprise_postage',
          'jy_enterprise_postage'
        ];
        arr.map(has => {
          if (this.parentLogin === 0) {
            if (has < 3) {
              this.params[has].map(item => {
                if (item.low_money > item.hign_money) {
                  this.$message.error('右侧订单金额需大于等于左侧订单金额！');
                  flag = false;
                  return;
                }
              });
            }
          } else {
            this.params[has].map(item => {
              if (item.low_money > item.hign_money) {
                this.$message.error('右侧订单金额需大于等于左侧订单金额！');
                flag = false;
                return;
              }
            });
          }
        });
        if (flag) {
          if (this.parentLogin === 0) {
            let params = {
              gys_source_postage: this.params.gys_source_postage,
              jy_source_postage: this.params.jy_source_postage,
              jd_source_postage: this.params.jd_source_postage
            }
            API_shop.saveSendMoney(params).then(res => {
              this.$message.success('保存成功！');
              this.getDetail();
            });
          } else {
            API_shop.saveSendMoney(this.params).then(res => {
              this.$message.success('保存成功！');
              this.getDetail();
            });
          }
        }
      }
    },
    getTongYi() {
      API_shop.getSendMsg().then(res => {
        // console.log(res)
        if (res.data.enterprisePostage) {
          for (let k in this.enterpriseParams) {
            this.enterpriseParams[k] = res.data.enterprisePostage[k];
          }
        }
        if (res.data.shopPostage) {
          for (let k in this.userParams) {
            this.userParams[k] = res.data.shopPostage[k];
          }
        }
      });
    },
    getDetail() {
      API_shop.getAllSendMsg({source_type: 1}).then(res => {
        if (!res.data.sourcePostages.length) {
          res.data.sourcePostages.push({
            hign_money: 0,
            low_money: 0,
            money: 0
          });
        }
        this.params.jd_source_postage = res.data.sourcePostages;
        if (!res.data.enterprisePostages.length) {
          res.data.enterprisePostages.push({
            hign_money: 0,
            low_money: 0,
            money: 0
          })
        }
        this.params.jd_enterprise_postage = res.data.enterprisePostages
      });
      API_shop.getAllSendMsg({source_type: 2}).then(res => {
        if (!res.data.sourcePostages.length) {
          res.data.sourcePostages.push({
            hign_money: 0,
            low_money: 0,
            money: 0
          });
        }
        this.params.jy_source_postage = res.data.sourcePostages;
        if (!res.data.enterprisePostages.length) {
          res.data.enterprisePostages.push({
            hign_money: 0,
            low_money: 0,
            money: 0
          })
        }
        this.params.jy_enterprise_postage = res.data.enterprisePostages
      });
      API_shop.getAllSendMsg({source_type: 3}).then(res => {
        if (!res.data.sourcePostages.length) {
          res.data.sourcePostages.push({
            hign_money: 0,
            low_money: 0,
            money: 0
          });
        }
        this.params.gys_source_postage = res.data.sourcePostages;
        if (!res.data.enterprisePostages.length) {
          res.data.enterprisePostages.push({
            hign_money: 0,
            low_money: 0,
            money: 0
          })
        }
        this.params.gys_enterprise_postage = res.data.enterprisePostages
      });
    },
    delMsg(arr) {
      /*for(let i=0;i<arr.length;i++){
					API_shop.delSendMsg(arr[i].id).then(res=>{
						console.log(res,"删除情况")
					})
				}*/
    }
  }
};
</script>

<style lang="scss" scoped>
.ss {
  color: #999;
}

.ssf {
  line-height: 1.7;
  margin-top: 8px;

  .ssf-title {
    b {
      font-size: 15px;
    }

    p {
      color: #999;
    }
  }

  .row {
    margin-bottom: 16px;
  }
}

/deep/ {
  .el-input {
    width: 70px;
    margin: 0 8px;
  }
}
</style>
